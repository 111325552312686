import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'

import {IConfirmOrdersParams, ICreateOrder, IOrdersGetParams} from '@/store/actionTypes/orders'

export const getOrders = createAsyncThunk(
    'orders/getOrders',
    async ({orderStatus, page, perPage, sortBy, orderBy}: IOrdersGetParams, thunkAPI) => {
        try {
            const response = await Api.Orders.orderListOrdersGet(
                orderStatus,
                page,
                perPage,
                sortBy,
                orderBy
            )
            return {pagination: response.data.pagination, orders: response.data.data.list}
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const confirmOrders = createAsyncThunk(
    'orders/confirmOrders',
    async (args: IConfirmOrdersParams, thunkAPI) => {
        try {
            await Api.Orders.confirmOrderOrdersConfirmPost(args)
            return {orders: args.order_id_list.map((id) => ({id}))}
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const createOrder = createAsyncThunk(
    'orders/createOrder',
    async (args: ICreateOrder, thunkAPI) => {
        try {
            const response = await Api.Orders.createOrderOrdersPost({
                article_id_list: args.article_id_list,
                language_id_list: args.language_id_list,
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)
