import {IBaseStateField} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {updateById} from '@/utils/updateArrays/updateById'

import {
    IConfirmOrdersReturn,
    IGetOrdersReturn,
    IOrder,
    IOrderId,
    IOrdersPage,
    OrderStatuses,
} from '@/store/actionTypes/orders'
import {confirmOrders, createOrder, getOrders} from '@/store/entities/orders/actions'

interface IOrdersState {
    getOrdersState: IBaseStateField
    confirmOrdersState: IBaseStateField
    orders: IOrder[]
    ordersPage: IOrdersPage | null
    choseOrders: IOrderId[]
    totalPages: number | null
    chosenIdArticleList: number[]
    createOrderState: IBaseStateField
}

const initialState: IOrdersState = {
    getOrdersState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    confirmOrdersState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    createOrderState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
    },
    choseOrders: [],
    orders: [],
    ordersPage: null,
    totalPages: null,
    chosenIdArticleList: [],
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        addChoseOrder: (state, action: PayloadAction<IOrderId>) => {
            state.choseOrders = updateById(
                state.choseOrders,
                action.payload.id,
                (order) => ({...order, id: action.payload.id}),
                () => ({id: action.payload.id})
            )
        },
        removeChoseOrder: (state, action: PayloadAction<IOrderId>) => {
            state.choseOrders = state.choseOrders.filter((order) => order.id !== action.payload.id)
        },
        resetConfirmOrdersState: (state) => {
            state.confirmOrdersState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        resetGetOrdersState: (state) => {
            state.getOrdersState = {
                isLoading: false,
                isSuccess: false,
                isError: false,
            }
        },
        addArticleId: (state, action: PayloadAction<number>) => {
            state.chosenIdArticleList.push(action.payload)
        },
        removeArticleId: (state, action: PayloadAction<number>) => {
            state.chosenIdArticleList = state.chosenIdArticleList.filter(
                (id) => id !== action.payload
            )
        },
    },
    extraReducers: {
        [getOrders.pending.type]: (state) => {
            state.getOrdersState.isLoading = true
            state.getOrdersState.isSuccess = false
            state.getOrdersState.isError = false
        },
        [getOrders.fulfilled.type]: (state, action: PayloadAction<IGetOrdersReturn>) => {
            state.getOrdersState.isLoading = false
            state.getOrdersState.isSuccess = true
            state.getOrdersState.isError = false

            state.totalPages = action.payload.pagination.total_pages
            action.payload.orders.forEach((newOrder) => {
                state.orders = updateById(
                    state.orders,
                    newOrder.id,
                    (order) => ({...order, ...newOrder}),
                    () => newOrder
                )
            })
            state.ordersPage = {
                page: action.payload.pagination.page,
                orders: action.payload.orders.map((order) => order.id),
            }
        },
        [getOrders.rejected.type]: (state) => {
            state.getOrdersState.isLoading = false
            state.getOrdersState.isSuccess = false
            state.getOrdersState.isError = true
        },
        [confirmOrders.pending.type]: (state) => {
            state.confirmOrdersState.isLoading = true
            state.confirmOrdersState.isSuccess = false
            state.confirmOrdersState.isError = false
        },
        [confirmOrders.fulfilled.type]: (state, action: PayloadAction<IConfirmOrdersReturn>) => {
            state.confirmOrdersState.isLoading = false
            state.confirmOrdersState.isSuccess = true
            state.confirmOrdersState.isError = false
            action.payload.orders.forEach((order) => {
                state.orders = updateById(state.orders, order.id, (order) => ({
                    ...order,
                    status: OrderStatuses.confirmed,
                }))
            })
            state.choseOrders = []
        },
        [confirmOrders.rejected.type]: (state) => {
            state.confirmOrdersState.isLoading = false
            state.confirmOrdersState.isSuccess = false
            state.confirmOrdersState.isError = true
        },
        [createOrder.pending.type]: (state) => {
            state.createOrderState.isLoading = true
            state.createOrderState.isSuccess = false
            state.createOrderState.isError = false
        },
        [createOrder.fulfilled.type]: (state) => {
            state.createOrderState.isLoading = false
            state.createOrderState.isSuccess = true
            state.createOrderState.isError = false
        },
        [createOrder.rejected.type]: (state) => {
            state.createOrderState.isLoading = false
            state.createOrderState.isSuccess = false
            state.createOrderState.isError = true
        },
    },
})

export default ordersSlice.reducer
