export enum AppPaths {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    RESET = '/reset',
    CONFIRM = '/confirm',
    NEW_PASSWORD = '/new-password',
    NOT_FOUND = '*',
    CONFIRM_PASSWORD = '/confirm-password',

    INFO = '/info',
    HOME = '/',
    PROFILE = '/profile',
    CONTACTS = '/contacts',
    ORDERS = '/orders',
    REPORTS = '/reports',
    MAP = '/map',
    ORDER_TRANSLATION = '/order_translation',
    SETTINGS = '/settings',
    NOTIFICATIONS = '/notifications',

    PRODUCTS = '/products',
    ARTICLES = '/articles',
    NEW_ARTICLE = '/new',
    EDIT_ARTICLE = '/:id/edit',
    VIEW_ARTICLE = '/:id/content',
    REPORT_ARTICLE = '/articles/:id/report',
    COLLECTIONS = '/collections',
    CATEGORIES = '/categories',
    STORES = '/stores',
    CLIENTS = '/clients',
    PROMOCODES = '/promocodes',
    DISCOUNTS = '/discounts',
    TOOLS = '/tools',
    CONNECTIONS = '/connections',
    TELEGRAM = '/telegram',
    SUPPORT = '/support',
    HELP = '/help',
    DATA_EXCHANGE = '/data-exchange',
    IMPORT_DATA = '/import',
    EXPORT_DATA = '/export',
    SHIFTS = '/shifts',
    CHART_SHIFTS = '/chart-shifts',
    ROUTES = '/routes',

    INFOBOX = '/infobox',
    PAYMENTS = '/payments',
    PAYMENT_SUCCESSFUL = '/payment/successful',
    PAYMENT_FAILED = '/payment/failed',

    MODERATOR_REPORTS = '/moderator/reports',

    TRANSLATOR_SETTINGS = '/translator-settings',
    CONFIGS = '/configs',
    PROMPTS = '/prompt',
    ACTIVE_CONFIGS = '/active-configs',

    ADMIN_WORKERS = '/admin/workers',
    ADMIN_USERS_ANALYTICS = '/admin/users/analytics',
    ADMIN_SETTINGS = '/admin/settings',
    ADMIN_NOTIFICATIONS = '/admin/notifications',
    ADMIN_TICKETS = '/admin/tickets',
    ADMIN_TICKET_DETAIL = '/admin/ticketdetail',
    ADMIN_ANALYTICS = '/admin/analytics',
    ADMIN_CONTENT = '/admin/content',
    ADMIN_ROLES = '/admin/roles',
    ADMIN_PRODUCTS_PRICES = '/admin/products-prices',
    ADMIN_TRANSACTIONS = '/admin/transactions',
    ADMIN_TRANSLATIONS = '/admin/translations',
}

export enum AuthUrl {
    login = '/auth/login/',
    refresh = '/auth/refresh/',
    logout = '/auth/logout/',
}

export const ArticlePaths = {
    INDEX: '/articles',
    NEW: 'new',
    EDIT: (id: number) => `/articles/${id}/edit`,
    CONTENT: (id: number) => `/articles/${id}/content`,
    REPORT: (id: number) => `${AppPaths.ARTICLES}/${id}/report`,
}

export const ClientUrl = {
    CLIENT_EDIT: (id: number) => `/clients/${id}/edit`,
    CLIENT: (id: number) => `/clients/${id}`,
}

export const ReportsPaths = {
    REPORT: (id: number) => `${AppPaths.REPORTS}/${id}`,
}
