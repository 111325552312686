import {IPage, IPagination} from '@/types/type'

export const OrderStatuses = {
    created: 'created',
    confirmed: 'confirmed',
    done: 'done',
    refund: 'refund',
} as const

export interface IOrdersGetParams {
    orderStatus?: keyof typeof OrderStatuses
    page?: number
    perPage?: number
    sortBy?: 'created_at'
    orderBy?: 'desc' | 'asc'
}

export interface IOrder {
    id: number
    article_id: number
    target_lang_id: number
    price: number
    status: keyof typeof OrderStatuses
    group_id?: string
    translation_article_id?: number
}

export interface IGetOrdersReturn {
    pagination: IPagination
    orders: IOrder[]
}

export interface IOrdersPage extends IPage<'orders', number>{}

export interface IConfirmOrdersParams {
    order_id_list: number[]
}

export interface IConfirmOrdersReturn {
    orders: IOrderId[]
}

export interface IOrderId {
    id: number
}

export interface ICreateOrder {
    article_id_list: number[]
    language_id_list: number[]
}
