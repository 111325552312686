import {IBaseStateField, IErrorType, IResponse, JwtPayload} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import {TokenService} from '@/api/TokenService'

import {ISession} from '../../types/ISession'

import {
    login,
    // validateTokenResetEmail,
    registration,
    resendTokenConfirmEmail,
    // logoutUserAction,
    resetPasswordSendEmail,
    setNewPassword,
    validateTokenConfirmEmail,
} from './actions'

import {IToken} from '@/store/actionTypes/auth'

export interface AuthState {
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    confirmTokenFromEmail: null | string
    emailToVerify: null | string
    message: null | string
    user: null | JwtPayload
    token: string | null
    refreshToken: string | null
    continue_uri: string | null
    isSuccessProfileLoad: boolean
    sessions: ISession[]
    page: number
    totalItems: number
    itemsPerPage: number
    isDeactivate: boolean
    registrationError: {
        first_name: null | string[]
        last_name: null | string[]
        email: null | string[]
        phone_number: null | string[]
        password: null | string[]
        password2: null | string[]
        business_type: null | string[]
        region: null | string[]
        _schema: null | string[]
    }
    registrationState: IBaseStateField
    loginError: IErrorType | null
    isChangePhoneNumber: boolean
    isChangeEmail: boolean
    resetPasswordState: IBaseStateField
    verifyEmailState: IBaseStateField & {email: string | null}
    resendConfirmTokenState: IBaseStateField,
}

const initialState: AuthState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    confirmTokenFromEmail: null,
    emailToVerify: null,
    message: null,
    user: null,
    token: TokenService.isTokenExists() ? TokenService.getToken() : null,
    refreshToken: TokenService.isRefreshedTokenExists() ? TokenService.getRefreshedToken() : null,
    continue_uri: TokenService.isContinueUriExists() ? TokenService.getContinueUri() : null,
    isSuccessProfileLoad: false,
    sessions: [],
    page: 1,
    totalItems: 0,
    itemsPerPage: 0,
    isDeactivate: false,
    registrationError: {
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        password: null,
        password2: null,
        business_type: null,
        region: null,
        _schema: null,
    },
    registrationState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
    loginError: null,
    isChangePhoneNumber: false,
    isChangeEmail: false,
    verifyEmailState: {
        email: null,
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
    resendConfirmTokenState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
    resetPasswordState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
    },
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (
            state,
            action: PayloadAction<{
                token: string | null
                refreshToken: string | null
            }>
        ) => {
            if (action.payload.token && action.payload.refreshToken) {
                TokenService.setToken(action.payload.token)
                TokenService.setRefreshedToken(action.payload.refreshToken)
                state.user = jwtDecode(action.payload.token)
                state.isSuccessProfileLoad = true
            }
            state.token = action.payload.token
            state.refreshToken = action.payload.refreshToken
        },
        logoutUser: (state) => {
            state.token = null
            state.refreshToken = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.confirmTokenFromEmail = null
            state.message = null
            state.user = null
            state.isSuccessProfileLoad = false
            TokenService.removeToken()
            TokenService.removeRefreshedToken()
        },
        resetAuth: (state) => {
            state.token = null
            state.refreshToken = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = null
            state.user = null
            state.isSuccessProfileLoad = false
            state.registrationError = initialState.registrationError
        },
        setTokenFromEmail: (state, action: PayloadAction<{token: string | null}>) => {
            state.confirmTokenFromEmail = action.payload.token
            state.isSuccess = true
        },
        setVerifyEmail: (state, action: PayloadAction<{email: string | null}>) => {
            state.verifyEmailState.email = action.payload.email
        },
    },
    extraReducers: {
        // Вход
        [login.pending.type]: (state) => {
            state.isLoading = true
            state.loginError = null
            state.isError = false
        },
        [login.fulfilled.type]: (state, action: PayloadAction<IToken>) => {
            state.isLoading = false
            state.isSuccess = true
            state.token = action.payload?.auth_token
            state.refreshToken = action.payload?.refresh_token
            state.isSuccessProfileLoad = true
        },
        [login.rejected.type]: (state, action) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload?.message || 'System error'
            state.loginError = action.payload.result ? action.payload.result : null
        },
        // Регистрация
        [registration.pending.type]: (state) => {
            state.registrationState.isLoading = true
            state.registrationState.isSuccess = false
            state.registrationState.isError = false
        },
        [registration.fulfilled.type]: (state) => {
            state.registrationState.isLoading = false
            state.registrationState.isSuccess = true
            state.registrationState.isError = false
        },
        [registration.rejected.type]: (state) => {
            state.registrationState.isLoading = false
            state.registrationState.isSuccess = false
            state.registrationState.isError = true
        },
        // Проверка кода подтверждения
        [validateTokenConfirmEmail.pending.type]: (state) => {
            state.verifyEmailState.isLoading = true
            state.verifyEmailState.isSuccess = false
            state.verifyEmailState.isError = false
        },
        [validateTokenConfirmEmail.fulfilled.type]: (state) => {
            state.verifyEmailState.isLoading = false
            state.verifyEmailState.isSuccess = true
            state.verifyEmailState.isError = false
        },
        [validateTokenConfirmEmail.rejected.type]: (state) => {
            state.verifyEmailState.isLoading = false
            state.verifyEmailState.isSuccess = false
            state.verifyEmailState.isError = true
        },
        // Переотправка кода подтверждения
        [resendTokenConfirmEmail.pending.type]: (state) => {
            state.resendConfirmTokenState.isLoading = true
            state.resendConfirmTokenState.isSuccess = false
            state.resendConfirmTokenState.isError = false
        },
        [resendTokenConfirmEmail.fulfilled.type]: (state) => {
            state.resendConfirmTokenState.isLoading = false
            state.resendConfirmTokenState.isSuccess = true
            state.resendConfirmTokenState.isError = false
        },
        [resendTokenConfirmEmail.rejected.type]: (state) => {
            state.resendConfirmTokenState.isLoading = false
            state.resendConfirmTokenState.isSuccess = false
            state.resendConfirmTokenState.isError = true
        },
        // Выход
        // [logoutUserAction.pending.type]: (state) => {
        //     state.isLoading = true
        // },
        // [logoutUserAction.fulfilled.type]: (state) => {
        //     TokenService.removeToken()
        //     TokenService.removeRefreshedToken()
        //     state.token = null
        //     state.refreshToken = null
        //     state.isLoading = false
        //     state.isSuccess = false
        //     state.isError = false
        //     state.confirmTokenFromEmail = null
        //     state.message = null
        //     state.user = null
        //     state.isSuccessProfileLoad = false
        // },
        // [logoutUserAction.rejected.type]: (state) => {
        //     TokenService.removeToken()
        //     TokenService.removeRefreshedToken()
        //     state.token = null
        //     state.refreshToken = null
        //     state.isLoading = false
        //     state.isSuccess = false
        //     state.isError = false
        //     state.confirmTokenFromEmail = null
        //     state.message = null
        //     state.user = null
        //     state.isSuccessProfileLoad = false
        // },

        //resetPasswordSendEmail
        [resetPasswordSendEmail.pending.type]: (state) => {
            state.isLoading = true
        },
        [resetPasswordSendEmail.fulfilled.type]: (state) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
        },
        [resetPasswordSendEmail.rejected.type]: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
        },

        // //
        // [validateTokenResetEmail.pending.type]: (state) => {
        //     state.isLoading = true
        // },
        // [validateTokenResetEmail.fulfilled.type]: (state) => {
        //     state.isLoading = false
        //     state.isSuccess = true
        //     state.isError = false
        // },
        // [validateTokenResetEmail.rejected.type]: (state, action) => {
        //     state.isLoading = false
        //     state.isSuccess = false
        //     state.isError = true
        // },

        // //setNewPassword
        [setNewPassword.pending.type]: (state) => {
            state.isLoading = true
        },
        [setNewPassword.fulfilled.type]: (state) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
        },
        [setNewPassword.rejected.type]: (state, action) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
        },
    },
})

export const {logoutUser, setTokenFromEmail} = authSlice.actions

export default authSlice.reducer
