import {lazy} from 'react'

import {AppPaths} from './routes-enums'

const HomePage = lazy(() => import('@/pages/Home/Home'))
// const Clients = lazy(() => import('@/pages/Clients/Clients'))
// const Support = lazy(() => import('@/pages/Support/Support'))
// const Help = lazy(() => import('@/pages/Help/Help'))
// const Workers = lazy(() => import('src/pages/Workers'))
// const UsersAnalytics = lazy(() => import('@/pages/Workers/UsersAnalytics'))
const Content = lazy(() => import('@/pages/Content/Content'))
const TranslatorSettings = lazy(() => import('@/pages/TranslatorSettings/TranslatorSettings'))
// const Notifications = lazy(() => import('@/pages/Notifications/Notifications'))
// const Tickets = lazy(() => import('@/pages/Tickets/Tickets'))
// const TicketDetail = lazy(
//     () => import('@/pages/Tickets/TicketsTable/TicketsActions/TicketDetailModal/TicketDetail')
// )
// const Analytics = lazy(() => import('@/pages/Tickets/TicketsControls/Analytics/Analytics'))
// const Settings = lazy(() => import('@/pages/Settings/Settings'))
const ResetPassword = lazy(() => import('@/pages/ResetPassword/ResetPassword'))
const ConfirmEmail = lazy(() => import('@/pages/ConfirmEmail/ConfirmEmail'))
const ConfirmPassword = lazy(() => import('@/pages/ConfirmPassword/ConfirmPassword'))
const NewPassword = lazy(() => import('@/pages/NewPassword/NewPassword'))
const NotFound = lazy(() => import('@/pages/NotFound/NotFound'))
const Orders = lazy(() => import('@/pages/Orders/Orders'))
const Report = lazy(() => import('@/pages/Report/Report'))
const Reports = lazy(() => import('@/pages/Reports/Reports'))
const Registration = lazy(() => import('@/pages/Registration/Registration'))
const Contacts = lazy(() => import('@/pages/Contacts/Contacts'))
// const Tools = lazy(() => import('@/pages/Tools/Tools'))
const Connections = lazy(() => import('@/pages/Connections/Connections'))
// const Orders = lazy(() => import('@/pages/Orders'))
const PaymentFailed = lazy(() => import('@/pages/PaymentStatus/PaymentFailed/PaymentFailed'))
const PaymentSuccessful = lazy(
    () => import('@/pages/PaymentStatus/PaymentSuccessful/PaymentSuccessful')
)
const Products = lazy(() => import('@/pages/Products/Products'))
const ProductsPrices = lazy(() => import('@/pages/ProductsPrices/ProductsPrices'))
const Settings = lazy(() => import('@/pages/Settings/Settings'))
const Notifications = lazy(() => import('@/pages/Notifications/Notifications'))
const Transactions = lazy(() => import('@/pages/Transactions/Transactions'))
const OrderTranslation = lazy(() => import('@/pages/OrderTranslation/OrderTranslation'))
const Info = lazy(() => import('@/pages/Info/Info'))
const Translations = lazy(() => import('@/pages/Translations/Translations'))
// const Collections = lazy(() => import('@/pages/Collections/Collections'))
// const Categories = lazy(() => import('@/pages/Categories/Categories'))
// const Stores = lazy(() => import('@/pages/Stores/Stores'))
// const Promocodes = lazy(() => import('@/pages/Promocodes/Promocodes'))
// const Shifts = lazy(() => import('@/pages/Shifts'))
// const ChartShifts = lazy(() => import('@/pages/ChartShifts/ChartShifts'))
const Routes = lazy(() => import('@/pages/Routes/Routes'))
// const Infobox = lazy(() => import('@/pages/Infobox/Infobox'))

// const Payments = lazy(() => import('@/pages/Payments/Payments'))
// const Discounts = lazy(() => import('@/pages/Discounts/Discounts'))

// const DataExchange = lazy(() => import('@/pages/DataExchange/DataExchange'))
// const ExportData = lazy(() => import('@/pages/ExportData/ExportData'))
// const ImportData = lazy(() => import('@/pages/ImportData/ImportData'))

// const Roles = lazy(() => import('@/pages/Roles'))
const Articles = lazy(() => import('@/pages/Content/Content'))
const ArticleCreate = lazy(() => import('@/pages/Content/Articles/ArticleCreate/ArticleCreate'))
const ArticleEdit = lazy(() => import('@/pages/Content/Articles/ArticleEdit/ArticleEdit'))
const Article = lazy(() => import('@/pages/Content/Articles/components/ArticlePage/Article'))

// import {ChartShifts} from '@/pages/ChartShifts/ChartShifts'

import SignIn from '@/pages/SignIn/SignIn'
import SiteMap from '@/pages/SiteMap/SiteMap'

// import { ChartShifts } from '@/pages/ChartShifts/ChartShifts'

export interface IRoute {
    path: string
    component: JSX.Element
}

export const routes: IRoute[] = [
    {path: AppPaths.CONFIRM_PASSWORD + '/:token/', component: <NewPassword />},
    {path: AppPaths.CONFIRM + '/:token?/', component: <ConfirmEmail />},
    {path: AppPaths.MAP, component: <SiteMap />},
    // {path: AppPaths.NEW_PASSWORD, component: <NewPassword />},
    {path: AppPaths.NOT_FOUND, component: <NotFound />},
]

export const publicRoutes: IRoute[] = [
    {path: AppPaths.LOGIN, component: <SignIn />},
    {path: AppPaths.REGISTRATION, component: <Registration />},
    {path: AppPaths.RESET, component: <ResetPassword />},
    {path: AppPaths.CONFIRM_PASSWORD, component: <ConfirmPassword />},
    {path: AppPaths.INFO, component: <Info />},
]

export const privateRoutes: IRoute[] = [
    {path: AppPaths.HOME, component: <HomePage />},
    {path: AppPaths.CONTACTS, component: <Contacts />},
    {path: AppPaths.MODERATOR_REPORTS, component: <Reports />},
    {path: AppPaths.ORDER_TRANSLATION, component: <OrderTranslation />},
    // {path: AppPaths.CLIENTS + '/*', component: <Clients />},
    // {path: AppPaths.SUPPORT, component: <Support />},
    // {path: AppPaths.HELP + '/*', component: <Help />},
    // {path: AppPaths.ADMIN_TICKET_DETAIL, component: <TicketDetail />},
    // {path: AppPaths.TOOLS + '/*', component: <Tools />},
    // {path: AppPaths.PAYMENTS + '/*', component: <Payments />},
    {path: AppPaths.PAYMENT_SUCCESSFUL, component: <PaymentSuccessful />},
    {path: AppPaths.PAYMENT_FAILED, component: <PaymentFailed />},
    {path: AppPaths.CONNECTIONS + '/*', component: <Connections />},
    {path: AppPaths.ARTICLES + '/*', component: <Articles />},
    {path: AppPaths.NEW_ARTICLE, component: <ArticleCreate />},
    {path: AppPaths.EDIT_ARTICLE, component: <ArticleEdit />},
    {path: AppPaths.VIEW_ARTICLE, component: <Article />},
    {path: AppPaths.REPORT_ARTICLE, component: <Report />},
    {path: AppPaths.REPORTS + '/:id/', component: <Report />},
    {path: AppPaths.ORDERS, component: <Orders />},
    {path: AppPaths.PRODUCTS + '/*', component: <Products />},
    {path: AppPaths.SETTINGS, component: <Settings />},
    {path: AppPaths.NOTIFICATIONS, component: <Notifications />},
    // {path: AppPaths.COLLECTIONS, component: <Collections />},
    // {path: AppPaths.CATEGORIES + '/*', component: <Categories />},
    // {path: AppPaths.STORES + '/*', component: <Stores />},
    // {path: AppPaths.DISCOUNTS + '/*', component: <Discounts />},
    // {path: AppPaths.PROMOCODES, component: <Promocodes />},
    // {path: AppPaths.SHIFTS, component: <Shifts />},
    {path: AppPaths.ROUTES, component: <Routes />},
    // {path: AppPaths.INFOBOX + '/*', component: <Infobox />},
]

export const adminRoutes: IRoute[] = [
    // {path: AppPaths.ADMIN_WORKERS + '/*', component: <Workers />},
    // {path: AppPaths.ADMIN_USERS_ANALYTICS, component: <UsersAnalytics />},
    {path: AppPaths.ADMIN_CONTENT + '/*', component: <Content />},
    {path: AppPaths.TRANSLATOR_SETTINGS + '/*', component: <TranslatorSettings />},
    {path: AppPaths.ADMIN_PRODUCTS_PRICES + '/*', component: <ProductsPrices />},
    {path: AppPaths.ADMIN_TRANSACTIONS + '/*', component: <Transactions />},
    {path: AppPaths.ADMIN_TRANSLATIONS + '/*', component: <Translations />},
    // {path: AppPaths.ADMIN_NOTIFICATIONS + '/*', component: <Notifications />},
    // {path: AppPaths.ADMIN_TICKETS, component: <Tickets />},
    // {path: AppPaths.ADMIN_TICKET_DETAIL, component: <TicketDetail />},
    // {path: AppPaths.ADMIN_ANALYTICS, component: <Analytics />},
    // {path: AppPaths.ADMIN_SETTINGS + '/*', component: <Settings />},
    // {path: AppPaths.IMPORT_DATA + '/*', component: <ImportData />},
    // {path: AppPaths.EXPORT_DATA + '/*', component: <ExportData />},
    // {path: AppPaths.DATA_EXCHANGE + '/*', component: <DataExchange />},
    // {path: AppPaths.CHART_SHIFTS + '/*', component: <ChartShifts />},
    // {path: AppPaths.ADMIN_ROLES + '/*', component: <Roles />},
]

export const FORBIDDEN_USER = [
    AppPaths.ADMIN_CONTENT,
    AppPaths.ADMIN_PRODUCTS_PRICES,
    AppPaths.MODERATOR_REPORTS,
    AppPaths.ADMIN_TRANSACTIONS,
    AppPaths.ADMIN_TRANSLATIONS,
    AppPaths.REPORTS,
]
export const FORBIDDEN_MODERATOR: string[] = [
    AppPaths.ORDERS,
    AppPaths.ADMIN_PRODUCTS_PRICES,
    AppPaths.PRODUCTS,
    AppPaths.SETTINGS,
    AppPaths.ADMIN_TRANSACTIONS,
    AppPaths.ADMIN_TRANSLATIONS,
    AppPaths.CONTACTS,
]
export const FORBIDDEN_ADMIN = [
    AppPaths.ADMIN_NOTIFICATIONS,
    AppPaths.DATA_EXCHANGE,
    AppPaths.ORDERS,
    AppPaths.MODERATOR_REPORTS,
    AppPaths.REPORTS,
]
