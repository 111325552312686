import {cancelErrors} from '@/types/cancelErrors'

import axiosLib, {AxiosInstance, AxiosRequestConfig} from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import {createApiUrl} from '@/api/createApiUrl'
import {AuthApi, PasswordRecoveryApi, RegistrationApi} from '@/api/swaggerApi/oauth'
import {
    ArticleApi,
    BalanceApi,
    ConfigApi,
    ActiveConfigApi,
    PromptApi,
    ItemsApi, 
    LanguageApi, 
    NotificationApi,
    OrdersApi,
    PaymentMethodsApi,
    PurchaseApi,
    ReportApi,
    SettingsApi,
    TransactionApi,
    TranslationApi,
} from '@/api/swaggerApi/translator'

import {TokenService} from '@/api/TokenService'

import {AuthUrl} from '@/routes/routes-enums'

import {AppDispatch} from '@/store'
import {logoutUser} from '@/store/entities/auth/authSlice'

const oauthUrl = createApiUrl(
    process.env.REACT_APP_DEVELOPMENT_OAUTH_API_URL,
    process.env.REACT_APP_OAUTH_API_URL
) as string
const translatorUrl = createApiUrl(
    process.env.REACT_APP_DEVELOPMENT_TRANSLATOR_API_URL,
    process.env.REACT_APP_TRANSLATOR_API_URL
) as string

async function getRefreshToken() {
    const refreshToken = TokenService.getRefreshedToken()
    return axiosLib.post(oauthUrl + AuthUrl.refresh, undefined, {
        params: {refresh_token: refreshToken},
    })
}

export class Api {
    static Auth: AuthApi

    static Articles: ArticleApi

    static Registration: RegistrationApi

    static Report: ReportApi

    static Notification: NotificationApi

    static Items: ItemsApi

    static PaymentMethods: PaymentMethodsApi

    static Purchase: PurchaseApi

    static Balance: BalanceApi

    static PasswordRecovery: PasswordRecoveryApi

    static Orders: OrdersApi

    static Language: LanguageApi

    static Settings: SettingsApi

    static Transaction: TransactionApi

    static Translation: TranslationApi

    oauthService: AxiosInstance

    translatorService: AxiosInstance

    dispatch: AppDispatch

    static Api: AuthApi

    static Prompt: PromptApi

    static Config: ConfigApi

    static ActiveConfig: ActiveConfigApi

    constructor(dispatch: AppDispatch) {
        this.dispatch = dispatch
        this.oauthService = this.initService(oauthUrl)
        this.translatorService = this.initService(translatorUrl)
    }

    private initService = (serviceUrl: string): AxiosInstance => {
        let service = axiosLib.create({
            baseURL: serviceUrl,
            withCredentials: true,
            httpsAgent: {rejectUnauthorized: false},
        })

        service.interceptors.response.use(this.handleSuccess, this.handleError)
        service.interceptors.request.use(
            (request) => {
                if (!request?.headers) {
                    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`)
                }
                const token = TokenService.getToken()
                request.headers.Authorization = 'Bearer ' + token
                return request
            },
            (error) => Promise.reject(error)
        )
        createAuthRefreshInterceptor(service, this.refreshAuthLogic, {
            retryInstance: service,
            onRetry: (requestConfig) => ({...requestConfig, baseURL: serviceUrl}),
        })

        return service
    }

    init = () => {
        Api.Auth = new AuthApi(undefined, undefined, this.oauthService)
        Api.Registration = new RegistrationApi(undefined, undefined, this.oauthService)
        Api.PasswordRecovery = new PasswordRecoveryApi(undefined, undefined, this.oauthService)

        Api.Report = new ReportApi(undefined, undefined, this.translatorService)
        Api.Prompt = new PromptApi(undefined, undefined, this.translatorService);
        Api.Config = new ConfigApi(undefined, undefined, this.translatorService);
        Api.ActiveConfig = new ActiveConfigApi(undefined, undefined, this.translatorService)
        Api.Notification = new NotificationApi(undefined, undefined, this.translatorService)
        Api.Articles = new ArticleApi(undefined, undefined, this.translatorService)
        Api.Balance = new BalanceApi(undefined, undefined, this.translatorService)
        Api.Orders = new OrdersApi(undefined, undefined, this.translatorService)
        Api.Language = new LanguageApi(undefined, undefined, this.translatorService)
        Api.Items = new ItemsApi(undefined, undefined, this.translatorService)
        Api.PaymentMethods = new PaymentMethodsApi(undefined, undefined, this.translatorService)
        Api.Purchase = new PurchaseApi(undefined, undefined, this.translatorService)
        Api.Settings = new SettingsApi(undefined, undefined, this.translatorService)
        Api.Transaction = new TransactionApi(undefined, undefined, this.translatorService)
        Api.Translation = new TranslationApi(undefined, undefined, this.translatorService)
    }

    handleSuccess(response: AxiosRequestConfig) {
        return response
    }

    async handleError(error: any) {
        if (axiosLib.isCancel(error)) return Promise.reject(cancelErrors.cancelToken)
        return Promise.reject(error)
    }

    refreshAuthLogic = async (failedRequest: any) => {
        const originalConfig = failedRequest.config
        // if (!TokenService.isRefreshedTokenExists()) return Promise.resolve()
        try {
            const {data} = await getRefreshToken()
            if (data.success) {
                const {auth_token, refresh_token} = data.result
                TokenService.setToken(auth_token)
                TokenService.setRefreshedToken(refresh_token)
                originalConfig.headers.Authorization = `Bearer ${auth_token}`
                return await Promise.resolve()
            } else {
                this.dispatch(logoutUser())

                return await Promise.reject()
            }
        } catch (e) {
            this.dispatch(logoutUser())
            return Promise.reject()
        }
    }

    _getProgress(callback: (percentCompleted: number) => void = () => {}) {
        return {
            onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                callback(percentCompleted)
            },
        }
    }
}

export function getCancelTokenSource() {
    return axiosLib.CancelToken.source()
}

export function updateCancelToken(data: any) {
    if (data === undefined || data === null) return {}
    if (data.source) data.source.cancel('Operation canceled due to new request.')
    data.source = getCancelTokenSource()
    return data.source.token
}
